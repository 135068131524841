import { useMenuHandlers } from '@/components/organisms/Menu/hooks/useMenuHandlers';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import MenuItem from '@mui/material/MenuItem';
import { memo, useCallback } from 'react';
import { StyledMenuContainer, StyledMenuPosition } from './styled';

type Props = {
  onClose: () => void;
};
/**
 * JSDoc
 * @see 白抜きメニュー
 */
export const WhiteMenu = memo(
  ({ onClose: handleClose }: Props): JSX.Element => {
    const { activateTargetFeature } = useFeaturesContext({});
    const { openDialogWithCheckBatchData } = useMenuHandlers();
    const handleOpenBackgroundChild = useCallback(
      (name: string) => {
        if (openDialogWithCheckBatchData(name)) return;

        activateTargetFeature(name);
        handleClose();
      },
      [activateTargetFeature, handleClose, openDialogWithCheckBatchData],
    );

    return (
      <>
        <StyledMenuPosition>
          <StyledMenuContainer>
            <MenuItem
              onClick={() => {
                handleOpenBackgroundChild('white');
              }}
            >
              一枚のパターンを生成
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenBackgroundChild('multiWhite');
              }}
            >
              複数枚のパターンを生成
            </MenuItem>
          </StyledMenuContainer>
        </StyledMenuPosition>
      </>
    );
  },
);
