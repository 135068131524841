import { Box, BoxProps } from '@/components/atoms/Box';
import hexToRgba from 'hex-to-rgba';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledMenu = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledMenu} {...props}>
      {children}
    </Box>
  );
});

export const StyledMenuBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledMenuBox} {...props}>
      {children}
    </Box>
  );
});

type MenuLinkProps = {
  isActive: boolean;
  isDisable: boolean;
} & BoxProps;

export const StyledMenuLink = memo(
  ({ children, isActive, isDisable, ...props }: MenuLinkProps) => {
    const menuLinkStyle = {
      color: isActive
        ? 'var(--color-gray-pale)'
        : !isDisable
        ? 'var(--color-gray-white)'
        : hexToRgba('#999', 0.4),
      backgroundColor: isActive
        ? 'var(--color-gray-deep)'
        : !isDisable
        ? ''
        : '',
      border: isActive
        ? `4px solid ${'var(--color-gray-deep)'}`
        : !isDisable
        ? ``
        : ``,
      ':hover': {
        color: isActive ? '' : !isDisable ? '' : '',
        backgroundColor: '',
        border: !isDisable ? `` : ``,
        cursor: !isDisable ? 'pointer' : '',
      },
    };

    return (
      <Box className={vanilla.styledMenuLink} sx={menuLinkStyle} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledPartitionLine = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledPartitionLine} {...props}>
      {children}
    </Box>
  );
});

type MenuTextProps = {
  isActive: boolean;
  isDisable: boolean;
} & BoxProps;

export const StyledMenuText = memo(
  ({ children, isActive, isDisable, ...props }: MenuTextProps) => {
    const menuTextStyle = {
      color: isActive
        ? 'var(--color-gray-pale)'
        : !isDisable
        ? 'var(--color-gray-white)'
        : hexToRgba('#999', 0.4),
    };

    return (
      <Box className={vanilla.styledMenuText} style={menuTextStyle} {...props}>
        {children}
      </Box>
    );
  },
);

type MenuIconProps = {
  isDisable: boolean;
} & BoxProps;

export const StyledMenuIcon = memo(
  ({ children, isDisable, ...props }: MenuIconProps) => {
    const menuIconStyle = {
      opacity: !isDisable ? 1 : 0.3,
    };

    return (
      <Box className={vanilla.styledMenuIcon} sx={menuIconStyle} {...props}>
        {children}
      </Box>
    );
  },
);
