import { Layout } from '@/components/templates/Layout';
import { useFeaturesContext } from '@/contexts/FeaturesContext';
import { Feature } from '@/contexts/FeaturesContext/types';
import { Fragment, createElement } from 'react';

export const Index = () => {
  const { featuresContext } = useFeaturesContext({});

  return (
    <Layout>
      {featuresContext.length > 0 &&
        featuresContext.map((d: Feature, i: number) => (
          <Fragment key={String(`${d.main.name}-${i}`)}>
            {d.main.isActive && d.main.component && (
              <>{createElement(d.main.component)}</>
            )}
          </Fragment>
        ))}
    </Layout>
  );
};

export default Index;
