import { Snackbar } from '@/components/atoms/Snackbar';
import { Content } from '@/components/organisms/Content';
import { Menu } from '@/components/organisms/Menu';
import { StyledFlexCenterExpanded } from '@/components/styled';
import { GoogleAnalytics } from '@/components/templates/GoogleAnalytics';
import { ConfirmErrorDialog } from '@/features/components/modals/ConfirmErrorDialog';
import { useError } from '@/hooks/global/useError';
import { useUser } from '@auth0/nextjs-auth0/client';
import { memo } from 'react';
import { Auth } from './Auth';
import { StyledBody, StyledFrame } from './styled';

type Props = {
  children: React.ReactNode;
};

/**
 * JSDoc
 * @see Sugekaeのレイアウトコンポーネント
 * @see アプリのレイアウトを定義している
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const Layout = memo(({ children }: Props) => {
  const { user, error, isLoading: isAuth0Loading } = useUser();
  const { axiosError, isError, setIsError, generatingStatus } = useError();

  return (
    <>
      <GoogleAnalytics />
      <Auth user={user} error={error} isAuth0Loading={isAuth0Loading}>
        <StyledBody>
          <Snackbar />
          <StyledFrame>
            <StyledFlexCenterExpanded>
              <Menu />
              <Content>{children}</Content>
            </StyledFlexCenterExpanded>
          </StyledFrame>
        </StyledBody>
        {isError && (
          <ConfirmErrorDialog
            isError={isError}
            setIsError={setIsError}
            axiosError={axiosError}
            errorCode={
              axiosError?.response?.status || generatingStatus || undefined
            }
          />
        )}
      </Auth>
    </>
  );
});
