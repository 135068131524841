import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledMenuPosition = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledMenuPosition} {...props}>
      {children}
    </Box>
  );
});

export const StyledMenuContainer = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledMenuContainer} {...props}>
      {children}
    </Box>
  );
});
