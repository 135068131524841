import { Box, BoxProps } from '@/components/atoms/Box';
import { forwardRef, memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledContent = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...props }, ref) => {
    return (
      <Box ref={ref} className={vanilla.styledContent} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledContentPadding = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledContentPadding} {...props}>
      {children}
    </Box>
  );
});
