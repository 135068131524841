import { Box, BoxProps } from '@/components/atoms/Box';
import { MotionProps, motion } from 'framer-motion';
import React, { memo } from 'react';
import * as vanilla from './vanilla.css';

type StyledSnackbarMotionDivProps = {
  children?: React.ReactNode;
} & MotionProps;

export const StyledSnackbarMotionDiv = memo(
  ({ children, ...props }: StyledSnackbarMotionDivProps) => {
    return (
      <motion.div className={vanilla.styledSnackbarMotionDiv} {...props}>
        {children}
      </motion.div>
    );
  },
);

export const StyledWordWrapper = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledWordWrapper} {...props}>
      {children}
    </Box>
  );
});

export const StyledSnackbarContent = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSnackbarContent} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledSnackbarIconBox = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSnackbarIconBox} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledSnackbarTextPreLine = memo(
  ({ children, ...props }: BoxProps) => {
    return (
      <Box className={vanilla.styledSnackbarTextPreLine} {...props}>
        {children}
      </Box>
    );
  },
);

export const StyledCloseIconBox = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledCloseIconBox} {...props}>
      {children}
    </Box>
  );
});
