import { Box, BoxProps } from '@/components/atoms/Box';
import { memo } from 'react';
import * as vanilla from './vanilla.css';

export const StyledBody = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledBody} {...props}>
      {children}
    </Box>
  );
});

export const StyledFrame = memo(({ children, ...props }: BoxProps) => {
  return (
    <Box className={vanilla.styledFrame} {...props}>
      {children}
    </Box>
  );
});
