import { useMenuHandlers } from '@/components/organisms/Menu/hooks/useMenuHandlers';
import { useFeaturesContext } from '@/contexts/FeaturesContext/';
import MenuItem from '@mui/material/MenuItem';
import { memo, useCallback } from 'react';
import { StyledMenuContainer, StyledMenuPosition } from './styled';

type Props = {
  onClose: () => void;
};
/**
 * JSDoc
 * @see 管理者用のポッパーセレクトメニュー
 * @see メインメニューの管理をホバー時に展開される
 * @param {Props} props
 * @returns {JSX.Element}
 */
export const AdminMenu = memo(
  ({ onClose: handleClose }: Props): JSX.Element => {
    const { activateTargetFeature, activateTargetSub } = useFeaturesContext({});
    const { openDialogWithCheckBatchData } = useMenuHandlers();

    const handleOpenAdminChild = useCallback(
      (name: string) => {
        if (openDialogWithCheckBatchData(name)) return;

        activateTargetFeature('admin');
        activateTargetSub(name);
        handleClose();
      },
      [
        activateTargetFeature,
        activateTargetSub,
        handleClose,
        openDialogWithCheckBatchData,
      ],
    );

    return (
      <>
        <StyledMenuPosition>
          <StyledMenuContainer>
            <MenuItem
              onClick={() => {
                handleOpenAdminChild('userList');
              }}
            >
              ユーザー管理
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenAdminChild('downloadManagement');
              }}
            >
              ダウンロード管理
            </MenuItem>
          </StyledMenuContainer>
        </StyledMenuPosition>
      </>
    );
  },
);
