import { StyledFont16White } from '@/components/styled';
import { useSnackbar } from '@/hooks/global/useSnackbar';
import { useIcons } from '@/hooks/utils/useIcons';
import { AnimatePresence } from 'framer-motion';
import { memo, useState } from 'react';
import { MdClose } from 'react-icons/md';
import {
  StyledCloseIconBox,
  StyledSnackbarContent,
  StyledSnackbarIconBox,
  StyledSnackbarMotionDiv,
  StyledSnackbarTextPreLine,
  StyledWordWrapper,
} from './styled';

type Props = {
  timer?: number;
  // placement?: 'rightTop, centerBottom, etc..' /* TODO: 出現場所を設定するなら */
};

/**
 * JSDoc
 * @see スナックバーコンポーネント
 * @see スナックバーを表示する際に使用する
 * @param {Props} props
 * @returns {JSX.Element}
 * @todo 出現場所を設定するなら
 * @todo スナックバーの出現場所を設定する
 * @see muiのsnackbarは細かなポジション指定が出来ず、アニメーションもしょぼいので使わない
 * @see LayoutにてSnackbarを配置し元のコンポーネントでの記述を減らしている
 * @see storybookがない
 */
export const Snackbar = memo(({ timer = 2400 }: Props) => {
  const {
    isOpenSnackbar,
    setIsOpenSnackbar,
    snackbarParam,
    handleCloseSnackbar,
  } = useSnackbar();
  const { returnIcon } = useIcons();
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  return (
    <>
      <AnimatePresence>
        {isOpenSnackbar && (
          <StyledSnackbarMotionDiv
            initial={{ opacity: 0, x: snackbarParam?.position?.x || 0, y: 50 }}
            animate={{
              opacity: 1,
              x: snackbarParam?.position?.x || 0,
              y: snackbarParam?.position?.y || 0,
            }}
            exit={{
              opacity: 0,
              x: snackbarParam?.position?.x || 0,
              y: snackbarParam?.position?.y || 0,
            }}
            onAnimationComplete={() => {
              setTimerId(
                setTimeout(() => {
                  setIsOpenSnackbar(false);
                }, timer),
              );
            }}
            onAnimationStart={() => {
              if (!timerId) return;
              clearTimeout(timerId);
            }}
            transition={{
              duration: 0.1,
              delay: 0,
            }}
          >
            <StyledSnackbarContent>
              <StyledWordWrapper>
                <StyledSnackbarIconBox>
                  {returnIcon(snackbarParam?.iconName)}
                </StyledSnackbarIconBox>
                <StyledSnackbarTextPreLine>
                  <StyledFont16White>{snackbarParam?.text}</StyledFont16White>
                </StyledSnackbarTextPreLine>
                <StyledCloseIconBox
                  data-testid="close-snackbar"
                  onClick={handleCloseSnackbar}
                >
                  <MdClose size={24} color={'var(--color-gray-white)'} />
                </StyledCloseIconBox>
              </StyledWordWrapper>
            </StyledSnackbarContent>
          </StyledSnackbarMotionDiv>
        )}
      </AnimatePresence>
    </>
  );
});
